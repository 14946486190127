import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"
import Logo from "../../assets/svg/logo.svg"
import SVG from "react-inlinesvg"
import { LayoutWrapper } from "../LayoutWrapper/LayoutWrapper"

const StyledFooter = styled.footer`
  margin-top: auto;
  font-size: 16px;
  color: #ffffff;
  padding-top: 100px;
  @media screen and (max-width: 1200px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }
`

const StyledBackground = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  background-color: #15232f;
  @media screen and (max-width: 1000px) {
    padding-bottom: 40px;
  }
`

const StyledWrapper = styled(LayoutWrapper)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

const StyledLogo = styled(SVG)`
  * {
    fill: #ffffff;
  }
`

const StyledMailContact = styled.a`
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  max-width: 200px;
`

const StyledDisclaimer = styled.p`
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
  margin-top: 1em;
`

const StyledColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    align-items: center;
    margin-bottom: 40px;
  }
`

const StyledLinksList = styled.ul`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-style: normal;
  line-height: 33px;
  margin-right: -15px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const StyledMenuItemWrapper = styled.div`
  padding: 0 30px;
  position: relative;

  ::after {
    content: "";
    opacity: 0.8;
    position: absolute;
    top: 100%;
    height: 1px;
    left: 50%;
    right: 50%;
    background: ${({ transparent }) => (transparent ? "#ffffff" : "#000000")};
    transition: all 0.2s;
  }

  @media (hover: hover),
    not all and (-moz-touch-enabled) and (max-width: 1200px) {
    &.active::after,
    :hover::after {
      left: 30px;
      right: 30px;
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 0px 15px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0;
  }
`

const StyledSubmenuContent = styled.div`
  cursor: pointer;
  span {
    font-weight: 700;
  }
  ul {
    margin-top: 10px;
  }
  &:hover ul {
    display: block;
  }
`

const StyledLabelWrapper = styled(Link)`
  font-weight: 700;
`

const Footer = ({ menuItems }) => (
  <StyledFooter>
    <StyledBackground>
      <StyledWrapper>
        <StyledColumnWrapper>
          <Link to="/">
            <StyledLogo src={Logo} className="logo" />
          </Link>
          <StyledMailContact>
            © 2020 Akademia Alkantara{" "}
            <a href="mailto:kontakt@akademia-alkantara.pl">
              kontakt@akademia-alkantara.pl
            </a>
          </StyledMailContact>
          <StyledDisclaimer>
            Program Alkantara prowadzony jest przez Fundację Inspirujące
            Przykłady
          </StyledDisclaimer>
        </StyledColumnWrapper>
        <StyledLinksList>
          {menuItems.map((menuItem) => {
            if (menuItem.link) {
              return (
                <StyledMenuItemWrapper>
                  <StyledLabelWrapper
                    activeClassName="active"
                    to={menuItem.link}
                  >
                    {menuItem.label}
                  </StyledLabelWrapper>
                </StyledMenuItemWrapper>
              )
            } else {
              return (
                <StyledMenuItemWrapper>
                  <StyledSubmenuContent>
                    <span>{menuItem.label}</span>
                    <ul>
                      {menuItem.submenu.map((submenuItem) => {
                        return (
                          <li>
                            <Link to={submenuItem.link}>
                              {submenuItem.label}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </StyledSubmenuContent>
                </StyledMenuItemWrapper>
              )
            }
          })}
        </StyledLinksList>
      </StyledWrapper>
    </StyledBackground>
  </StyledFooter>
)

export default Footer
