import { Link } from "gatsby"
import styled, { css } from "styled-components"
import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import Logo from "../../assets/svg/logo.svg"
import { LayoutWrapper } from "../LayoutWrapper/LayoutWrapper"
import { color } from "../../utils/colors"
import { fonts } from "../../utils/fonts"
import Submenu from "../Submenu/Submenu"

const StyledLogo = styled(SVG)`
  svg * {
    //fill: ${({ transparent }) => (transparent ? `white` : ``)};
    transition: all 0.2s;
  }

  &.menu-open {
    @media screen and (max-width: 767px) {
      svg * {
        fill: ${({ transparent }) => (transparent ? `black` : ``)};
      }
    }
  }
`

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 18px;
  font-style: normal;
  line-height: 33px;
  background-color: ${({ transparent }) =>
    transparent ? `transparent` : `white`};
  //color: ${({ transparent }) => (transparent ? `white` : ``)};
  ${({ transparent }) =>
    !transparent &&
    css`
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    `}

  transition: .2s;
  @media screen and (max-width: 1200px) {
    color: black;
  }

  > a {
    z-index: 2;
  }
`

const StyledWrapper = styled(LayoutWrapper)`
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    height: 70px;
    color: black;
  }
  @media screen and (max-width: 767px) {
    height: 60px;
  }
`

const StyledNavigation = styled.nav`
  display: flex;
  flex-direction: row;
  margin: 0 -30px;
  @media screen and (max-width: 1200px) {
    position: fixed;
    top: 0;
    padding-top: 90px;
    bottom: 0;
    right: 0;
    width: 300px;
    margin: 0;
    background: #ffffff;
    flex-direction: column;
    transform: translate(${({ menuOpen }) => (menuOpen ? "0" : "100%")});
    transition: all 0.2s;
    padding-right: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-left: 6%;
    padding-right: 6%;
  }
`

const StyledLabelWrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  ${({ isSpecial }) =>
    !isSpecial &&
    css`
      @media screen and (min-width: 1201px) {
        &::after {
          content: "";
          height: 30px;
          width: 1px;
          background-color: #ebebeb;
          position: absolute;
          right: 0;
          top: 5px;
        }
      }
    `};
  @media screen and (max-width: 1200px) {
    justify-content: flex-end;
  }
`

const StyledMenuLabel = styled.span`
  ${fonts.paragraph};
  height: 40px;
  padding: 0 30px;
  display: inline-flex;
  align-items: center;
  position: relative;
  opacity: 0.5;
  transition: opacity 0.2s;
  @media screen and (max-width: 1200px) {
    text-align: right;
    padding: 0;
    font-weight: 700;
    opacity: 1;
  }
  ${({ isSpecial }) =>
    isSpecial &&
    css`
      margin: 0 30px;
      border: solid 1px ${color.black};
      border-radius: 5px;
      transition: opacity 0.2s;
      padding: 0 16px !important;
      @media screen and (max-width: 1200px) {
        margin: 0;
        font-weight: 400 !important;
      }
    `};
  @media (hover: hover), not all and (-moz-touch-enabled) {
    :hover {
      opacity: 1;
    }
  }
`

const StyledMenuItemWrapper = styled.div`
  @media screen and (max-width: 1200px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`

const StyledBurger = styled.button`
  height: 18px;
  width: 24px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: normal;
  ${({ transparent }) =>
    transparent
      ? `span {background-color: black}`
      : `span {background-color: black}`};

  span {
    height: 2px;
    width: 100%;
    display: block;
    position: relative;
    transition: all 0.2s;
  }

  &.menu-open {
    span {
      background-color: black;
    }
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const Header = ({ isHeaderTransparent, menuItems }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [headerTransparent, setHeaderTransparent] = useState(
    isHeaderTransparent
  )

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.pageYOffset > 0
        ? setHeaderTransparent(false)
        : setHeaderTransparent(true)
    })
  }, [])

  useEffect(() => {
    setHeaderTransparent(isHeaderTransparent)
  }, [isHeaderTransparent])

  return (
    <StyledHeader transparent={headerTransparent}>
      <StyledWrapper>
        <Link to="/">
          <StyledLogo
            transparent={headerTransparent}
            src={Logo}
            className="logo"
          />
        </Link>
        <StyledBurger
          onClick={() => setMenuOpen(!menuOpen)}
          id="burger"
          transparent={headerTransparent}
        >
          <span />
          <span />
          <span />
        </StyledBurger>
        <StyledNavigation id="menu" menuOpen={menuOpen}>
          {menuItems.map(({ link, label, submenu, special }) => {
            if (link) {
              return (
                <StyledMenuItemWrapper transparent={isHeaderTransparent}>
                  <StyledLabelWrapper
                    activeClassName="active"
                    onClick={() => setMenuOpen(false)}
                    isSpecial={special}
                  >
                    <StyledMenuLabel
                      isSpecial={special}
                      to={link}
                      as={link && Link}
                    >
                      {label}
                    </StyledMenuLabel>
                  </StyledLabelWrapper>
                </StyledMenuItemWrapper>
              )
            } else {
              return (
                <StyledMenuItemWrapper>
                  <Submenu
                    isSpecial={special}
                    transparent={headerTransparent}
                    label={label}
                    submenu={submenu}
                  />
                </StyledMenuItemWrapper>
              )
            }
          })}
        </StyledNavigation>
      </StyledWrapper>
    </StyledHeader>
  )
}

export default Header
