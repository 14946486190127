import { css } from "styled-components"

export const fonts = {
  headingExtraLarge: css`
    font-size: 67px;
    font-style: normal;
    font-weight: 700;
    line-height: calc(87 / 67);
    letter-spacing: 0;
    @media screen and (max-width: 1300px) {
      font-size: 50px;
    }
    @media screen and (max-width: 767px) {
      font-size: 40px;
    }
  `,
  headingLarge: css`
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: calc(70 / 50);
    letter-spacing: 0px;
    @media screen and (max-width: 1300px) {
      font-size: 40px;
    }
    @media screen and (max-width: 767px) {
      font-size: 36px;
    }
  `,
  headingMedium: css`
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: calc(50 / 38);
    letter-spacing: 0;
    @media screen and (max-width: 1300px) {
      font-size: 28px;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  `,
  headingSmall: css`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: calc(40 / 28);
    letter-spacing: 0px;
    @media screen and (max-width: 1300px) {
      font-size: 18px;
    }
  `,
  headingExtraSmall: css`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: calc(35 / 24);
    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
  `,
  paragraphIntro: css`
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: calc(50 / 40);
    letter-spacing: 0px;
    @media screen and (max-width: 1300px) {
      font-size: 24px;
    }
  `,
  button: css`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: calc(21 / 18);
  `,
  paragraph: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: calc(30 / 18);
    letter-spacing: 0;
    //@media screen and (max-width: 1000px) {
    //  font-size: 21px;
    //}
  `,
  heading1: css`
    font-size: 60px;
    line-height: calc(80 / 60);
    font-weight: 700;
    @media screen and (max-width: 767px) {
      font-size: 30px;
    }
  `,
}
