import { Link } from "gatsby";
import styled, { css } from "styled-components";
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { color } from "../../utils/colors";
import { fonts } from "../../utils/fonts";
import IconTriangle from "../../assets/svg/icon_triangle-down.svg";


const StyledTriangleIcon = styled(SVG)`
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  transform: rotate(180deg);
`;

const StyledMenuLabel = styled.span`
  ${fonts.paragraph};
  height: 40px;
  padding: 0 30px;
  display: inline-flex;
  align-items: center;
  position: relative;
  opacity: 0.5;
  transition: opacity .2s;
  ${({ isSpecial }) => isSpecial && css`
    margin: 0 30px;
    border: solid 1px ${color.black};
    border-radius: 5px;
    transition: opacity .2s;
    padding: 0 16px !important;
    @media screen and (max-width: 1200px) {
      margin: 0;
    }
  `
  };
  @media (hover: hover),
  not all and (-moz-touch-enabled) {
    :hover {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1200px) {
    text-align: right;
    padding: 0;
    font-weight: 700;
    opacity: 1;
  }
`;

const StyledMenuItemWrapper = styled.div`
  position: relative;
  color: ${({ special }) => special && color.menuGrey};

  @media (hover: hover),
  not all and (-moz-touch-enabled) and (max-width: 1200px) {
    &.active::after,
    :hover::after {
      left: 30px;
      right: 30px;
    }
  }
  @media screen and (max-width: 1200px) {
    //padding: 10px 0;
    text-align: right;
  }
`;

const StyledSubmenuContent = styled.div`
  cursor: pointer;
  position: relative;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media (hover: hover),
  not all and (-moz-touch-enabled) {
    :hover {
      ${StyledTriangleIcon} {
        transform: rotate(0deg);
      }

      ${StyledMenuLabel} {
        opacity: 1;
      }
    }
  }

  ul {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
    top: 100%;
    left: 15px;
    right: 5px;
    padding: 0 25px;
    display: none;
    ${fonts.paragraph};
    background-color: ${({ transparent }) =>
            transparent ? "transparent" : color.white};
    @media screen and (max-width: 1200px) {
      position: relative;
      box-shadow: none;
      left: 0;
      right: 0;
      padding: 0;
      display: ${({ isOpen }) => isOpen ? 'block' : 'none'}
    }
  }
  @media screen and (min-width: 1201px) {
    &:hover ul {
      display: block;
    }
  }
`;

const StyledLinkItem = styled.li`

  :not(:last-child) {
    border-bottom: solid 1px #ebebeb;

  }
`;

const StyledSubmenuLink = styled(Link)`
  height: 50px;
  opacity: .5;
  transition: opacity .2s;

  display: inline-flex;
  align-items: center;
  @media (hover: hover),
  not all and (-moz-touch-enabled) {
    :hover {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1200px) {
    text-align: right;
    opacity: 1;
  }
`;


const StyledLabelWrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  ${({ isSpecial }) => !isSpecial && css`
    @media screen and (min-width: 1201px) {
      &::after {
        content: "";
        height: 30px;
        width: 1px;
        background-color: #ebebeb;
        position: absolute;
        right: 0;
        top: 5px;
      }
    }`
  };
  @media screen and (max-width: 1200px) {

    justify-content: flex-end;
  }


`;

const Submenu = ({ transparent, isSpecial, label, submenu }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <StyledMenuItemWrapper transparent={transparent}>
      <StyledSubmenuContent transparent={transparent} isOpen={menuOpen}>
        <StyledLabelWrapper
          isSpecial={isSpecial}
        >
          <StyledMenuLabel onClick={() => setMenuOpen(!menuOpen)}>{label}<StyledTriangleIcon
            src={IconTriangle} /></StyledMenuLabel>
        </StyledLabelWrapper>
        <ul>
          {submenu.map(({ link, label, special }) => {
            return (
              <StyledLinkItem>
                <StyledSubmenuLink to={link}>
                  {label}
                </StyledSubmenuLink>
              </StyledLinkItem>
            );
          })}
        </ul>
      </StyledSubmenuContent>
    </StyledMenuItemWrapper>
  );
};


export default Submenu;