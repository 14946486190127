import React from "react"
import styled from "styled-components"

import "./../../utils/sanitize.css"

import Header from "./../../components/Header/Header"
import Footer from "./../../components/Footer/Footer"

const menuItems = [
  {
    label: "Dlaczego Alkantara?",
    submenu: [
      { label: "Dla ucznia", link: "/dla-ucznia" },
      { label: "Dla rodzica", link: "/dla-rodzica" },
      { label: "Pytania i odpowiedzi", link: "/pytania-i-odpowiedzi" },
    ],
  },
  {
    label: "Co oferujemy",
    submenu: [
      { label: "Akademia", link: "/akademia" },
      { label: "Fundusz", link: "/fundusz" },
    ],
  },
  { label: "Aktualności", link: "/aktualnosci/" },
  { label: "Kontakt", link: "/kontakt" },
  { label: "Dla Partnerów", link: "/dla-partnerow", special: true },
]

const StyledLayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledMain = styled.main`
  padding-top: ${({ headerTransparent }) => (headerTransparent ? "" : "80px")};
  @media screen and (max-width: 1200px) {
    padding-top: ${({ headerTransparent }) =>
      headerTransparent ? "" : "70px"};
  }
  @media screen and (max-width: 767px) {
    padding-top: ${({ headerTransparent }) =>
      headerTransparent ? "" : "60px"};
  }
`

const Layout = ({ siteTitle, children, isHeaderTransparent }) => (
  <StyledLayoutContent>
    <div>
      <Header isHeaderTransparent={isHeaderTransparent} menuItems={menuItems} />
      <StyledMain headerTransparent={isHeaderTransparent}>
        {children}
      </StyledMain>
    </div>
    <Footer menuItems={menuItems} />
  </StyledLayoutContent>
)

export default Layout
