import styled from "styled-components"

export const LayoutWrapper = styled.div`
  max-width: 1180px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
